import React, {Component} from "react";
import fire from '../fire';
import '../App.css';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';

var iit = require('../Assets/iit.jpeg');

class Forgot extends Component{
	constructor(props){
 		super(props);
  		this.state={
    		email:'',
        message:'',
  		}	
	}
	forgotUser(){
		fire.auth().sendPasswordResetEmail(this.state.email).then(function() {
      this.setState({message:'An email has been sent containing the instructions to reset the password.'})
    }.bind(this)).catch(function(error) {
      this.setState({message:'There was an error in resetting the password. Please try again.'})
    }.bind(this));
	}
  render(){
    document.title="IIT Delhi'79 - Forgot Password"
    return(
    	<div id="home" style ={ { backgroundImage: "url("+iit+")" } }>
          <Header/>
          <div id="login">
      		<h3>Forgot Password</h3>
          <div class="fields">
          <p>Enter Email</p>
          <input type="email" onChange={(e)=>this.setState({email:e.target.value})}/>
          </div>
      		<button onClick={()=>this.forgotUser()}>Reset Password</button>
          <br/><br/>
          <p id="error">{this.state.message}</p>
          </div>
          <Footer/>
      </div>
    )
  }
};



export default Forgot;
