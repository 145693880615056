import firebase from "@firebase/app";
import "@firebase/auth";
import "@firebase/database";
import "@firebase/firestore";
import "@firebase/functions";
import "@firebase/storage";

let config = {
  apiKey: "AIzaSyDUYlnk7zNsVwvdLkUtuiUE0OgTqcvDvxI",
  authDomain: "iitd-9d64d.firebaseapp.com",
  databaseURL: "https://iitd-9d64d.firebaseio.com",
  projectId: "iitd-9d64d",
  storageBucket: "iitd-9d64d.appspot.com",
  messagingSenderId: "332186929520",
  appId: "1:332186929520:web:14675d10134ca4ad"
  };
  
let fire = firebase.initializeApp(config);
export default fire;