import React, {Component} from "react";
import '../App.css';

class Footer extends Component{
  
  render(){
    return(
    		<div id="footer">
    		<p>Handcrafted by <a href="https://www.linkedin.com/in/bansalvedant/" target="_blank">Vedant Bansal</a></p>
	      	</div>
    )
  }
};


export default Footer;
