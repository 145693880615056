import React, {Component} from "react";
import { Link } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import fire from '../fire';
import '../App.css';


// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
 
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
 
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
 
// Register the plugins
registerPlugin(FilePondPluginImagePreview);


var iit = require('../Assets/iit.jpeg');

class Profile extends Component{
  constructor(props){
    super(props);
    this.state={
      uid: '',
      email:'',
      FirstName:'',
      LastName:'',
      Year:'',
      LinkedIn:'',
      Discipline:'',
      Entrance:'',
      Hostel:'',
      PostGraduation:'',
      Phone:'',
      City:'',
      Spouse:'',
      Children:'',
      Pastime:'',
      Story:'',
      PastimeNow:'',
      Food:'',
      Movie:'',
      Actor:'',
      Career:'',
      Personal:'',
      Life:'',
      Plan:'',
      Impact:'',
      Picture1:'',
      progress1:'',
      progress2:'',
      progress3:'',
      progress4:'',
      progress5:'',
      progress6:'',
      message:'',
      error:'',
      Orientation1:'',
      Orientation2:'',
      Orientation3:'',
      Orientation4:'',
      Orientation5:'',
      Orientation6:'',
      Caption1:'',
      Caption2:'',
      Caption3:'',
      Caption4:'',
      Caption5:'',
      Caption6:'',
      file1:'',
      disabled1:'disabled',
      file2:'',
      disabled2:'disabled',
      file3:'',
      disabled3:'disabled',
      file4:'',
      disabled4:'disabled',
      file5:'',
      disabled5:'disabled',
      file6:'',
      disabled6:'disabled',
    }
  }
  componentDidMount(){
    fire.auth().onAuthStateChanged(function(user) {
      if (user) {
        iit = require('../Assets/collage.jpg');
        this.setState({uid:user.uid, email:user.email});
        fire.database().ref('/Profiles/' + user.uid).once('value').then(function(snapshot) {
          var userDetails = snapshot.val();
          this.setState({FirstName: userDetails.FirstName, LastName: userDetails.LastName, Year: userDetails.Year, LinkedIn: userDetails.LinkedIn, Discipline: userDetails.Discipline, Entrance: userDetails.Entrance, Hostel: userDetails.Hostel, PostGraduation: userDetails.PostGraduation, Phone: userDetails.Phone, City: userDetails.City, Spouse: userDetails.Spouse, Children: userDetails.Children, Pastime: userDetails.Pastime, Story: userDetails.Story, PastimeNow: userDetails.PastimeNow, Food: userDetails.Food, Movie: userDetails.Movie, Actor: userDetails.Actor, Career: userDetails.Career, Personal: userDetails.Personal, Life: userDetails.Life, Plan: userDetails.Plan, Impact: userDetails.Impact, Caption1: userDetails.Caption1, Caption2: userDetails.Caption2, Caption3: userDetails.Caption3, Caption4: userDetails.Caption4, Caption5: userDetails.Caption5, Caption6: userDetails.Caption6})
        }.bind(this));
      } else {
      }
    }.bind(this));
  }
  updateDetails() {
    if (this.state.FirstName != '' && this.state.LastName != '' && this.state.Year != '' && this.state.Discipline != '' && this.state.Entrance != '' && this.state.Hostel != '' && this.state.City != '' && this.state.Career != '') {
    fire.database().ref('/Profiles/' + this.state.uid).update({
      FirstName: this.state.FirstName,
      LastName: this.state.LastName,
      Year: this.state.Year,
      LinkedIn: this.state.LinkedIn,
      Discipline: this.state.Discipline,
      Entrance:this.state.Entrance,
      Hostel:this.state.Hostel,
      PostGraduation: this.state.PostGraduation,
      Phone: this.state.Phone,
      City: this.state.City,
      Spouse: this.state.Spouse,
      Children: this.state.Children,
      Pastime:this.state.Pastime,
      Story:this.state.Story,
      PastimeNow:this.state.PastimeNow,
      Food:this.state.Food,
      Movie:this.state.Movie,
      Actor:this.state.Actor,
      Career:this.state.Career,
      Personal:this.state.Personal,
      Life:this.state.Life,
      Plan:this.state.Plan,
      Impact:this.state.Impact,
      UID:this.state.uid,
      Email:this.state.email,
      Complete:'1',
    }).then(this.setState({message:'Changes have been saved', error:'',}));
    } else {
      this.setState({message:'Please fill in all the required fields.', error:'error'});
    }
  }
  handleInit1() {
        // console.log('FilePond instance has initialised', this.pond);
  }
  handleInit2() {
        // console.log('FilePond instance has initialised', this.pond);
  }
  handleInit3() {
        // console.log('FilePond instance has initialised', this.pond);
  }
  handleInit4() {
        // console.log('FilePond instance has initialised', this.pond);
  }
  handleInit5() {
        // console.log('FilePond instance has initialised', this.pond);
  }
  handleInit6() {
        // console.log('FilePond instance has initialised', this.pond);
  }


  upload1() {
    if (this.state.Orientation1 != '') {
    fire.database().ref('/Profiles/' + this.state.uid).update({
      Orientation1: this.state.Orientation1,
      Caption1:this.state.Caption1,
    })
    fire.storage().ref('images/' + this.state.uid + '_2.jpg').put(this.state.file1[0]).on('state_changed', function(snapshot){
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // console.log('Upload is ' + Math.floor(progress) + '% done');
    this.setState({progress1:'Upload is ' + Math.floor(progress) + '% done'});
    }.bind(this), function(error) {
    this.setState({progress1:'Error in uploading picture. Make sure it is less than 4MB.'});
    }.bind(this), function() {
    this.setState({progress1:'Picture successfully uploaded.'});
  }.bind(this));
  } else {
    this.setState({progress1:'Please select orientation before uploading.'})
  }
  }
  upload2() {
    if (this.state.Orientation2 != '') {
    fire.database().ref('/Profiles/' + this.state.uid).update({
      Orientation2: this.state.Orientation2,
      Caption2:this.state.Caption2,
    })
    fire.storage().ref('images/' + this.state.uid + '_4.jpg').put(this.state.file2[0]).on('state_changed', function(snapshot){
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // console.log('Upload is ' + Math.floor(progress) + '% done');
    this.setState({progress2:'Upload is ' + Math.floor(progress) + '% done'});
    }.bind(this), function(error) {
    this.setState({progress2:'Error in uploading picture. Make sure it is less than 4MB.'});
    }.bind(this), function() {
    this.setState({progress2:'Picture successfully uploaded.'});
  }.bind(this));
  } else {
    this.setState({progress2:'Please select orientation before uploading.'})
  }
  }
  upload3() {
    if (this.state.Orientation3 != '') {
    fire.database().ref('/Profiles/' + this.state.uid).update({
      Orientation3: this.state.Orientation3,
      Caption3:this.state.Caption3,
    })
    fire.storage().ref('images/' + this.state.uid + '_3.jpg').put(this.state.file3[0]).on('state_changed', function(snapshot){
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // console.log('Upload is ' + Math.floor(progress) + '% done');
    this.setState({progress3:'Upload is ' + Math.floor(progress) + '% done'});
    }.bind(this), function(error) {
    this.setState({progress3:'Error in uploading picture. Make sure it is less than 4MB.'});
    }.bind(this), function() {
    this.setState({progress3:'Picture successfully uploaded.'});
  }.bind(this));
  } else {
    this.setState({progress3:'Please select orientation before uploading.'})
  }
  }
  upload4() {
    if (this.state.Orientation4 != '') {
    fire.database().ref('/Profiles/' + this.state.uid).update({
      Orientation4: this.state.Orientation4,
      Caption4:this.state.Caption4,
    })
    fire.storage().ref('images/' + this.state.uid + '_1.jpg').put(this.state.file4[0]).on('state_changed', function(snapshot){
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // console.log('Upload is ' + Math.floor(progress) + '% done');
    this.setState({progress4:'Upload is ' + Math.floor(progress) + '% done'});
    }.bind(this), function(error) {
    this.setState({progress4:'Error in uploading picture. Make sure it is less than 4MB.'});
    }.bind(this), function() {
    this.setState({progress4:'Picture successfully uploaded.'});
  }.bind(this));
  } else {
    this.setState({progress4:'Please select orientation before uploading.'})
  }
  }
  upload5() {
    if (this.state.Orientation5 != '') {
    fire.database().ref('/Profiles/' + this.state.uid).update({
      Orientation5: this.state.Orientation5,
      Caption5:this.state.Caption5,
    })
    fire.storage().ref('images/' + this.state.uid + '_5.jpg').put(this.state.file5[0]).on('state_changed', function(snapshot){
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // console.log('Upload is ' + Math.floor(progress) + '% done');
    this.setState({progress5:'Upload is ' + Math.floor(progress) + '% done'});
    }.bind(this), function(error) {
    this.setState({progress5:'Error in uploading picture. Make sure it is less than 4MB.'});
    }.bind(this), function() {
    this.setState({progress5:'Picture successfully uploaded.'});
  }.bind(this));
  } else {
    this.setState({progress5:'Please select orientation before uploading.'})
  }
  }
  upload6() {
    if (this.state.Orientation6 != '') {
    fire.database().ref('/Profiles/' + this.state.uid).update({
      Orientation6: this.state.Orientation6,
      Caption6:this.state.Caption6,
    })
    fire.storage().ref('images/' + this.state.uid + '_6.jpg').put(this.state.file6[0]).on('state_changed', function(snapshot){
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // console.log('Upload is ' + Math.floor(progress) + '% done');
    this.setState({progress6:'Upload is ' + Math.floor(progress) + '% done'});
    }.bind(this), function(error) {
    this.setState({progress6:'Error in uploading picture. Make sure it is less than 4MB.'});
    }.bind(this), function() {
    this.setState({progress6:'Picture successfully uploaded.'});
  }.bind(this));
  } else {
    this.setState({progress6:'Please select orientation before uploading.'})
  }
  }
  confirm1() {
    if(window.confirm("Are you sure you want to delete the currently uploaded picture?")) {
      this.delete1();
    }
  }
  delete1() {
    fire.storage().ref('images/' + this.state.uid + '_2.jpg').delete().then(function() {
      this.setState({progress1:'Picture deleted successfully.'})
    }.bind(this)).catch(function(error) {
      this.setState({progress1:'Error in deleting picture.'})
    }.bind(this));
  }
  confirm2() {
    if(window.confirm("Are you sure you want to delete the currently uploaded picture?")) {
      this.delete1();
    }
  }
  delete2() {
    fire.storage().ref('images/' + this.state.uid + '_4.jpg').delete().then(function() {
      this.setState({progress2:'Picture deleted successfully.'})
    }.bind(this)).catch(function(error) {
      this.setState({progress2:'Error in deleting picture.'})
    }.bind(this));
  }
  confirm3() {
    if(window.confirm("Are you sure you want to delete the currently uploaded picture?")) {
      this.delete3();
    }
  }
  delete3() {
    fire.storage().ref('images/' + this.state.uid + '_3.jpg').delete().then(function() {
      this.setState({progress3:'Picture deleted successfully.'})
    }.bind(this)).catch(function(error) {
      this.setState({progress3:'Error in deleting picture.'})
    }.bind(this));
  }
  confirm4() {
    if(window.confirm("Are you sure you want to delete the currently uploaded picture?")) {
      this.delete4();
    }
  }
  delete4() {
    fire.storage().ref('images/' + this.state.uid + '_1.jpg').delete().then(function() {
      this.setState({progress4:'Picture deleted successfully.'})
    }.bind(this)).catch(function(error) {
      this.setState({progress4:'Error in deleting picture.'})
    }.bind(this));
  }
  confirm5() {
    if(window.confirm("Are you sure you want to delete the currently uploaded picture?")) {
      this.delete5();
    }
  }
  delete5() {
    fire.storage().ref('images/' + this.state.uid + '_5.jpg').delete().then(function() {
      this.setState({progress5:'Picture deleted successfully.'})
    }.bind(this)).catch(function(error) {
      this.setState({progress5:'Error in deleting picture.'})
    }.bind(this));
  }
  confirm6() {
    if(window.confirm("Are you sure you want to delete the currently uploaded picture?")) {
      this.delete6();
    }
  }
  delete6() {
    fire.storage().ref('images/' + this.state.uid + '_6.jpg').delete().then(function() {
      this.setState({progress6:'Picture deleted successfully.'})
    }.bind(this)).catch(function(error) {
      this.setState({progress6:'Error in deleting picture.'})
    }.bind(this));
  }
  componentDidUpdate(){
    if (this.state.file1.length == 0 && typeof(this.state.file1) == 'object') {
      this.setState({disabled1:'disabled', file1:''})
    }
    if (this.state.file2.length == 0 && typeof(this.state.file2) == 'object') {
      this.setState({disabled2:'disabled', file2:''})
    }
    if (this.state.file3.length == 0 && typeof(this.state.file3) == 'object') {
      this.setState({disabled3:'disabled', file3:''})
    }
    if (this.state.file4.length == 0 && typeof(this.state.file4) == 'object') {
      this.setState({disabled4:'disabled', file4:''})
    }
    if (this.state.file5.length == 0 && typeof(this.state.file5) == 'object') {
      this.setState({disabled5:'disabled', file5:''})
    }
    if (this.state.file6.length == 0 && typeof(this.state.file6) == 'object') {
      this.setState({disabled6:'disabled', file6:''})
    }
  }
  render(){
    document.title="IIT Delhi'79 - Edit Profile";
    return(
    		<div id="home" style ={ { backgroundImage: "url("+iit+")" } }>
            <Header/>
            <div id="profile">
	      		<h3>Update Profile</h3>
            <Link to="/">Go back to dashboard</Link>
            <br/><br/>
            <hr/>
            <br/>
            <p class="info">
            Welcome Batch of 1979. As you know we are having our 40th Reunion in January of 2020, to help in preparation of that we would like you to fill in as many details as possible below. It will benefit us all by –
            </p>
            <ol class="info">
            <li class="info">Getting to know a bit more about each other. It has been after all 40 years!</li>
            <li class="info">When we meet it will help in getting conversations going. Perhaps spouses and children will make new friends and find common threads.</li>
            <li class="info">The Committee plans to publish an year book about all of us so please write and upload pictures.</li>
            </ol>
            <p class="info">
            You can write as much or as little as you desire. After you have filled in the mandatory fields you will be able to see what others have written and their pictures too.
            </p><br/>
            <p class="info red">Fields marked in red are mandatory. <br/>You can save the form partially by clicking on the Save button found at the end of the section.</p>
            <hr/>
            <br/>
            <div class="fields">
            <p class="red">First Name</p>
            <input value={this.state.FirstName}  onChange={(e)=>this.setState({FirstName:e.target.value})}/>
            </div>
            <div class="fields">
            <p class="red">Last Name</p>
            <input value={this.state.LastName}  onChange={(e)=>this.setState({LastName:e.target.value})}/>
            </div>
            <div class="fields">
            <p class="red">Year of Joining</p>
            <input value={this.state.Year} onChange={(e)=>this.setState({Year:e.target.value})}/>
            </div>
            <div class="fields">
            <p class="red">Discipline</p>
            <select value={this.state.Discipline}  onChange={(e)=>this.setState({Discipline:e.target.value})}>
            <option value="">Select Discipline</option>
            <option value="B. Tech. - Electrical">B. Tech. - Electrical</option>
            <option value="B. Tech. - Chemical">B. Tech. - Chemical</option>
            <option value="B. Tech. - Mechanical">B. Tech. - Mechanical</option>
            <option value="B. Tech. - Civil">B. Tech. - Civil</option>
            <option value="B. Tech. - Textile">B. Tech. - Textile</option>
            <option value="MSc. - Physics">MSc. - Physics</option>
            <option value="MSc. - Chemistry">MSc. - Chemistry</option>
            <option value="MSc. - Maths">MSc. - Maths</option>
            <option value="MSc. - Other">MSc. - Other</option>
            <option value="Other">Other</option>
            </select>
            </div>
            <div class="fields">
            <p class="red">Entry Number</p>
            <input value={this.state.Entrance} onChange={(e)=>this.setState({Entrance:e.target.value})}/>
            </div>
            <div class="fields">
            <p class="red">Hostel Name</p>
            <select value={this.state.Hostel}  onChange={(e)=>this.setState({Hostel:e.target.value})}>
            <option value="">Select Hostel</option>
            <option value="Aravali">Aravali</option>
            <option value="Jwalamukhi">Jwalamukhi</option>
            <option value="Karakoram">Karakoram</option>
            <option value="Kumaon">Kumaon</option>
            <option value="Nilgiri">Nilgiri</option>
            <option value="Shivalik">Shivalik</option>
            <option value="Kailash">Kailash</option>
            <option value="Day Boarder">Day Boarder</option>
            </select>
            </div>
            <div class="fields">
            <p>Post Graduate Studies</p>
            <input value={this.state.PostGraduation} onChange={(e)=>this.setState({PostGraduation:e.target.value})}/>
            </div>
            <div class="fields">
            <p>Mobile Phone</p>
            <input value={this.state.Phone} type="tel" onChange={(e)=>this.setState({Phone:e.target.value})}/>
            </div>
            <div class="fields">
            <p class="red">Living City/Country</p>
            <input value={this.state.City} onChange={(e)=>this.setState({City:e.target.value})}/>
            </div>
            <div class="fields">
            <p class="red">Career Journey (max:2000 chars)</p>
            <textarea value={this.state.Career} placeholder="As brief or as elaborate as you want. You can also provide a link to your LinkedIn profile in the next field." rows="5" maxLength="2000" onChange={(e)=>this.setState({Career:e.target.value})}/>
            </div>
            <div class="fields">
            <p>LinkedIn Profile URL</p>
            <input value={this.state.LinkedIn} onChange={(e)=>this.setState({LinkedIn:e.target.value})}/>
            </div>
            <div class="fields">
            <small>Format - https://www.linkedin.com/your-id/</small>
            <br/><br/>
            </div>
            <div class="fields">
            <p>Spouse / Significant Other Details (max:1000 chars)</p>
            <textarea value={this.state.Spouse} placeholder="You can write their name, what they do, when you got married etc. Remember, this will help our spouses or significant others to know a bit about each other before they meet." rows="5" maxLength="1000" onChange={(e)=>this.setState({Spouse:e.target.value})}/>
            </div>
            <div class="fields">
            <p>Children Details (max:1000 chars)</p>
            <textarea value={this.state.Children} placeholder="You can write their names, where they are studying or graduated from, where they are working, married, kids etc." rows="5" maxLength="1000" onChange={(e)=>this.setState({Children:e.target.value})}/>
            </div>
            <div class="fields">
            <p>Favorite Pastime in IIT (max:1000 chars)</p>
            <textarea value={this.state.Pastime} placeholder="Could be a one liner like - Hanging out at Surd's" rows="5" maxLength="1000" onChange={(e)=>this.setState({Pastime:e.target.value})}/>
            </div>
            <div class="fields">
            <p>Share some significant / juicy IIT story(ies) (max:1000 chars)</p>
            <textarea value={this.state.Story} rows="5" maxLength="1000" onChange={(e)=>this.setState({Story:e.target.value})}/>
            </div>
            <div class="fields">
            <p>Favorite Pastime Now (max:1000 chars)</p>
            <textarea value={this.state.PastimeNow} rows="5" maxLength="1000" onChange={(e)=>this.setState({PastimeNow:e.target.value})}/>
            </div>
            <div class="fields">
            <p>Favorite Food(s) (max:1000 chars)</p>
            <textarea value={this.state.Food} rows="5" maxLength="1000" onChange={(e)=>this.setState({Food:e.target.value})}/>
            </div>
            <div class="fields">
            <p>Favorite Movie(s) (max:1000 chars)</p>
            <textarea value={this.state.Movie} rows="5" maxLength="1000" onChange={(e)=>this.setState({Movie:e.target.value})}/>
            </div>
            <div class="fields">
            <p>Favorite Actor/Actress/Singer (max:1000 chars)</p>
            <textarea value={this.state.Actor} rows="5" maxLength="1000" onChange={(e)=>this.setState({Actor:e.target.value})}/>
            </div>
            <div class="fields">
            <p>Personal Journey (max:2000 chars)</p>
            <textarea value={this.state.Personal} placeholder="A tricky one you say. How about writing how has life in general treated you?" rows="5" maxLength="2000" onChange={(e)=>this.setState({Personal:e.target.value})}/>
            </div>
            <div class="fields">
            <p>What event/person changed your life? (max:2000 chars)</p>
            <textarea value={this.state.Life} rows="5" maxLength="2000" onChange={(e)=>this.setState({Life:e.target.value})}/>
            </div>
            <div class="fields">
            <p>How do you plan to spend the rest of your precious days? (max:2000 chars)</p>
            <textarea value={this.state.Plan} rows="5" maxLength="2000" onChange={(e)=>this.setState({Plan:e.target.value})}/>
            </div>
            <div class="fields">
            <p>If you had 500 hours to devote or $100,000 to spend, how would you utilize it to make the most social impact on our planet? (max:2000 chars)</p>
            <textarea value={this.state.Impact} placeholder="Example - Fund an NGO working towards abolishing child labor, helping solve climate change etc." rows="5" maxLength="2000" onChange={(e)=>this.setState({Impact:e.target.value})}/>
            </div>
            <button id="submit" onClick={()=>this.updateDetails()}>Save Changes</button>
            <br/><br/>
            <p id={this.state.error}>{this.state.message}</p>
            <h3 id="pictureHeading">Upload Pictures</h3>
            <hr/><br/>
            <p class="info">
            A lot has happened in the last 4 decades. Please upload up to 6 pictures to make our memories even more memorable.
            Due to technical issues, sometimes the pictures appear disoriented.There is a box below each picture that asks you to select picture rotation to help us show the picture properly.
            <br/>
            <br/>
            To delete an uploaded picture, please press the 'Delete' button. To replace an existing picture, please upload another image; the current picture will get replaced.
            </p><br/>
            <br/>
            <div class="fields">
            <p>Your Current Picture</p>
            <FilePond labelIdle="Click to select a picture. Select the rotation as per preview." ref={ref => this.pond = ref}
                          files={this.state.file1}
                          allowMultiple={true}
                          maxFiles={1} 
                          oninit={() => this.handleInit1() }
                          onupdatefiles={fileItems => {
                              // Set currently active file objects to this.state
                              this.setState({
                                  file1: fileItems.map(fileItem => fileItem.file),
                                  disabled1:'',
                              });
                          }}>
              </FilePond>
            </div>
            <div class="fields">
            <small>Select Rotation <br/> Max size: 4MB</small>
            <select value={this.state.Orientation1}  onChange={(e)=>this.setState({Orientation1:e.target.value})} disabled={this.state.disabled1}>
            <option value="">Select Rotation</option>
            <option value="rotate(0deg)">Perfect</option>
            <option value="rotate(-90deg)">Rotate 90deg Anticlockwise</option>
            <option value="rotate(180deg)">Upside Down</option>
            <option value="rotate(90deg)">Rotate 90deg Clockwise</option>
            </select>
            </div>
            <div class="fields">
            <small>Image caption/desc (max: 100 chars)</small>
            <input maxLength="100" value={this.state.Caption1} onChange={(e)=>this.setState({Caption1:e.target.value})}></input>
            </div>
            
            <button onClick={()=>this.upload1()} class="upload" disabled={this.state.disabled1}>Upload</button>
            <button onClick={()=>this.confirm1()} class="delete">Delete</button>
            <br/><br/>
            <p>{this.state.progress1}</p>
            <br/><br/>
            <div class="fields">
            <p>Family Picture</p>
            <FilePond labelIdle="Click to select a picture. Select the rotation as per preview." ref={ref => this.pond = ref}
                          files={this.state.file2}
                          allowMultiple={true}
                          maxFiles={1} 
                          oninit={() => this.handleInit2() }
                          onupdatefiles={fileItems => {
                              // Set currently active file objects to this.state
                              this.setState({
                                  file2: fileItems.map(fileItem => fileItem.file),
                                  disabled2:'',
                              });
                          }}>
              </FilePond>
            </div>
            <div class="fields">
            <small>Select Rotation <br/> Max size: 4MB</small>
            <select value={this.state.Orientation2}  onChange={(e)=>this.setState({Orientation2:e.target.value})} disabled={this.state.disabled2}>
            <option value="">Select Rotation</option>
            <option value="rotate(0deg)">Perfect</option>
            <option value="rotate(-90deg)">Rotate 90deg Anticlockwise</option>
            <option value="rotate(180deg)">Upside Down</option>
            <option value="rotate(90deg)">Rotate 90deg Clockwise</option>
            </select>
            </div>
            <div class="fields">
            <small>Image caption/desc (max: 100 chars)</small>
            <input maxLength="100" value={this.state.Caption2} onChange={(e)=>this.setState({Caption2:e.target.value})}></input>
            </div>
            <button onClick={()=>this.upload2()} class="upload" disabled={this.state.disabled2}>Upload</button>
            <button onClick={()=>this.confirm2()} class="delete">Delete</button>
            <br/><br/>
            <p>{this.state.progress2}</p>
            <br/><br/>
            <div class="fields">
            <p>IIT Days Picture (1)</p>
            <FilePond labelIdle="Click to select a picture. Select the rotation as per preview." ref={ref => this.pond = ref}
                          files={this.state.file3}
                          allowMultiple={true}
                          maxFiles={1} 
                          oninit={() => this.handleInit3() }
                          onupdatefiles={fileItems => {
                              // Set currently active file objects to this.state
                              this.setState({
                                  file3: fileItems.map(fileItem => fileItem.file),
                                  disabled3:'',
                              });
                          }}>
              </FilePond>
            </div>
            <div class="fields">
            <small>Select Rotation <br/> Max size: 4MB</small>
            <select value={this.state.Orientation3}  onChange={(e)=>this.setState({Orientation3:e.target.value})} disabled={this.state.disabled3}>
            <option value="">Select Rotation</option>
            <option value="rotate(0deg)">Perfect</option>
            <option value="rotate(-90deg)">Rotate 90deg Anticlockwise</option>
            <option value="rotate(180deg)">Upside Down</option>
            <option value="rotate(90deg)">Rotate 90deg Clockwise</option>
            </select>
            </div>
            <div class="fields">
            <small>Image caption/desc (max: 100 chars)</small>
            <input maxLength="100" value={this.state.Caption3} onChange={(e)=>this.setState({Caption3:e.target.value})}></input>
            </div>
            <button onClick={()=>this.upload3()} class="upload" disabled={this.state.disabled3}>Upload</button>
            <button onClick={()=>this.confirm3()} class="delete">Delete</button>
            <br/><br/>
            <p>{this.state.progress3}</p>
            <br/><br/>
            <div class="fields">
            <p>IIT Days Picture (2)</p>
            <FilePond labelIdle="Click to select a picture. Select the rotation as per preview." ref={ref => this.pond = ref}
                          files={this.state.file4}
                          allowMultiple={true}
                          maxFiles={1} 
                          oninit={() => this.handleInit4() }
                          onupdatefiles={fileItems => {
                              // Set currently active file objects to this.state
                              this.setState({
                                  file4: fileItems.map(fileItem => fileItem.file),
                                  disabled4:'',
                              });
                          }}>
              </FilePond>
            </div>
            <div class="fields">
            <small>Select Rotation <br/> Max size: 4MB</small>
            <select value={this.state.Orientation4}  onChange={(e)=>this.setState({Orientation4:e.target.value})} disabled={this.state.disabled4}>
            <option value="">Select Rotation</option>
            <option value="rotate(0deg)">Perfect</option>
            <option value="rotate(-90deg)">Rotate 90deg Anticlockwise</option>
            <option value="rotate(180deg)">Upside Down</option>
            <option value="rotate(90deg)">Rotate 90deg Clockwise</option>
            </select>
            </div>
            <div class="fields">
            <small>Image caption/desc (max: 100 chars)</small>
            <input maxLength="100" value={this.state.Caption4} onChange={(e)=>this.setState({Caption4:e.target.value})}></input>
            </div>
            <button onClick={()=>this.upload4()} class="upload" disabled={this.state.disabled4}>Upload</button>
            <button onClick={()=>this.confirm4()} class="delete">Delete</button>
            <br/><br/>
            <p>{this.state.progress4}</p>
            <br/><br/>
            <div class="fields">
            <p>Additional Picture (1)</p>
            <FilePond labelIdle="Click to select a picture. Select the rotation as per preview." ref={ref => this.pond = ref}
                          files={this.state.file5}
                          allowMultiple={true}
                          maxFiles={1} 
                          oninit={() => this.handleInit5() }
                          onupdatefiles={fileItems => {
                              // Set currently active file objects to this.state
                              this.setState({
                                  file5: fileItems.map(fileItem => fileItem.file),
                                  disabled5:'',
                              });
                          }}>
              </FilePond>
            </div>
            <div class="fields">
            <small>Select Rotation <br/> Max size: 4MB</small>
            <select value={this.state.Orientation5}  onChange={(e)=>this.setState({Orientation5:e.target.value})} disabled={this.state.disabled5}>
            <option value="">Select Rotation</option>
            <option value="rotate(0deg)">Perfect</option>
            <option value="rotate(-90deg)">Rotate 90deg Anticlockwise</option>
            <option value="rotate(180deg)">Upside Down</option>
            <option value="rotate(90deg)">Rotate 90deg Clockwise</option>
            </select>
            </div>
            <div class="fields">
            <small>Image caption/desc (max: 100 chars)</small>
            <input maxLength="100" value={this.state.Caption5} onChange={(e)=>this.setState({Caption5:e.target.value})}></input>
            </div>
            <button onClick={()=>this.upload5()} class="upload" disabled={this.state.disabled5}>Upload</button>
            <button onClick={()=>this.confirm5()} class="delete">Delete</button>
            <br/><br/>
            <p>{this.state.progress5}</p>
            <br/><br/>
            <div class="fields">
            <p>Additional Picture (2)</p>
            <FilePond labelIdle="Click to select a picture. Select the rotation as per preview." ref={ref => this.pond = ref}
                          files={this.state.file6}
                          allowMultiple={true}
                          maxFiles={1} 
                          oninit={() => this.handleInit6() }
                          onupdatefiles={fileItems => {
                              // Set currently active file objects to this.state
                              this.setState({
                                  file6: fileItems.map(fileItem => fileItem.file),
                                  disabled6:'',
                              });
                          }}>
              </FilePond>
            </div>
            <div class="fields">
            <small>Select Rotation <br/> Max size: 4MB</small>
            <select value={this.state.Orientation6}  onChange={(e)=>this.setState({Orientation6:e.target.value})} disabled={this.state.disabled6}>
            <option value="">Select Rotation</option>
            <option value="rotate(0deg)">Perfect</option>
            <option value="rotate(-90deg)">Rotate 90deg Anticlockwise</option>
            <option value="rotate(180deg)">Upside Down</option>
            <option value="rotate(90deg)">Rotate 90deg Clockwise</option>
            </select>
            </div>
            <div class="fields">
            <small>Image caption/desc (max: 100 chars)</small>
            <input maxLength="100" value={this.state.Caption6} onChange={(e)=>this.setState({Caption6:e.target.value})}></input>
            </div>
            <button onClick={()=>this.upload6()} class="upload" disabled={this.state.disabled6}>Upload</button>
            <button onClick={()=>this.confirm6()} class="delete">Delete</button>
            <br/><br/>
            <p>{this.state.progress6}</p>
            <br/><br/>
            <Link to="/">Go back to dashboard</Link>
            </div>
            <Footer/>
	      	</div>
    )
  }
};



export default Profile;
