import React, {Component} from "react";
import fire from '../fire';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../App.css';

var iit = require('../Assets/iit.jpeg');

class SignUp extends Component{
	constructor(props){
 		super(props);
  		this.state={
    		email:'Enter email',
    		password:'Enter password',
        message:'',
        firstName:'',
        lastName:'',
        pId:'',
  		}	
	}
	componentDidMount(){
		
	}
  checkUser() {
    // fire.database().ref('/Emails').once('value').then(function(snapshot) {
    //   var emails = snapshot.val();
    //   var emailList = [];
    //   emails.map(function(d,idx) {
    //     emailList.push(d.Email);
    //   })
    //   if (emailList.includes(this.state.email) && this.state.firstName != '' && this.state.lastName != '') {
    //     this.createUser();
    //   } else if (emailList.includes(this.state.email) && this.state.firstName == '' && this.state.lastName != '') {
    //     this.setState({message:'First Name is a required field'})
    //   } else if (emailList.includes(this.state.email) && this.state.firstName != '' && this.state.lastName == '') {
    //     this.setState({message:'Last Name is a required field'})
    //   } else if (emailList.includes(this.state.email) && this.state.firstName == '' && this.state.lastName == '') {
    //     this.setState({message:'First and Last Name are required fields'})
    //   }
    //   else {
    //     this.setState({message:'Please tell administrator to add your email to the invite list.'})
    //   }
    // }.bind(this));
    if (this.state.firstName == '' && this.state.lastName != '') {
      this.setState({message:'First Name is a required field.', pId:"error"})
    } else if (this.state.firstName != '' && this.state.lastName == '') {
      this.setState({message:'Last Name is a required field.', pId:"error"})
    } else if (this.state.firstName == '' && this.state.lastName == '') {
      this.setState({message:'First and Last Name are required fields.', pId:"error"})
    } else {
      this.createUser();
    }
  }
	createUser(){
		fire.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).catch(function(error) {
  			// Handle Errors here.
  			var errorCode = error.code;
  			var errorMessage = error.message;
        // console.log(errorMessage);
        this.setState({message:errorMessage, pId:"error"});
        // ...
		}.bind(this)).then(()=>{
			var user = fire.auth().currentUser;
      fire.database().ref('/Profiles/' + user.uid).set({
        FirstName:this.state.firstName,
        LastName:this.state.lastName,
        UID:user.uid,
        Email:user.email,
        Complete:'0',
        LinkedIn:'',
        Year:'',
        Discipline:'',
        Entrance:'',
        Hostel:'',
        PostGraduation:'',
        Phone:'',
        City:'',
        Spouse:'',
        Children:'',
        Pastime:'',
        Story:'',
        PastimeNow:'',
        Food:'',
        Movie:'',
        Actor:'',
        Career:'',
        Personal:'',
        Life:'',
        Plan:'',
        Impact:'',
        Orientation1:'',
        Orientation2:'',
        Orientation3:'',
        Orientation4:'',
        Orientation5:'',
        Orientation6:'',
      });
      this.setState({message:'Your account has been successfully created. A verification link has been sent to your email id. Please click on the link to verify.'});
			user.sendEmailVerification().then(function() {
  			// Email sent.
			}).catch(function(error) {
  			// An error happened.
			});
      
		});

	}
  	render(){
      document.title="IIT Delhi'79 - Sign Up"
    return(
    	<div id="home" style ={ { backgroundImage: "url("+iit+")" } }>
          <Header/>
          <div id="login">
      		<h3>Sign Up</h3>	
          <div class="fields">
          <p>First Name</p>
          <input type="text"  onChange={(e)=>this.setState({firstName:e.target.value})}/>
          </div>
          <div class="fields">
          <p>Last Name</p>
          <input type="text"  onChange={(e)=>this.setState({lastName:e.target.value})}/>
          </div>
          <div class="fields">
          <p>Email</p>
      		<input type="email" onChange={(e)=>this.setState({email:e.target.value})}/>
      		</div>
          <div class="fields">
          <p>Password</p>
      		<input type="password" onChange={(e)=>this.setState({password:e.target.value})}/>
      		</div>
      		<button onClick={()=>this.checkUser()}>Submit</button>
          <br/><br/>
          <p id={this.state.pId}>{this.state.message}</p>
          </div>
          <Footer/>
      	</div>
    )
  }
};



export default SignUp;
