import React, {Component} from "react";
import { Link } from 'react-router-dom';
import fire from '../fire';
import '../App.css';

class Dashboard extends Component{
  constructor(props){
    super(props);
    this.state={
      uid:'',
      userLists:[],
      url:'',
      message:'Loading...',
    }
  }
  componentDidMount(){
    fire.auth().onAuthStateChanged(function(user) {
      if (user) {
        var userProfiles = [];
        var query = fire.database().ref('Profiles').orderByChild('FirstName');
        query.on('child_added', function(snap) {
          var userDetails = snap.val();
          userProfiles.push(userDetails);
          this.setState({uid:user.uid, userLists:userProfiles, message:''})
        }.bind(this));
    }
  }.bind(this))
  }
  render(){
    // console.log(this.state.userLists);
    return(
    		<div id="profile">
          <a href="https://www.flickr.com/gp/187672436@N02/P264Dd" target="_blank"><b>IIT Delhi 79 Ruby Reunion</b></a>
          <br/><br/>
          <a href="https://www.flickr.com/gp/187672436@N02/811Zjz" target="_blank"><b>Shared Memories</b></a>
          <br/><br/>
          <a href="https://www.flickr.com/gp/187672436@N02/JWrLFV" target="_blank"><b>IIT Delhi</b></a>
          <br/><br/>
          <a href="https://www.flickr.com/gp/187672436@N02/A5cUe6" target="_blank"><b>Videos</b></a>
          <br/><br/>
          <a href="https://docs.google.com/document/d/1fQlzGa9aVzjL7CI_ijFsfwSc8H2n_0zAVg8u8eNgIvc/edit?usp=sharing" target="_blank"><b>Ubuntu (Life Reflections)</b></a>
          <hr/>
          <h1>Dashboard</h1>
          <h3>Let us get to know each other a little better</h3>
          <Link to="/profile"><small id="edit-profile">Edit my profile</small></Link>
          <br/>
          <Link to={"/user/"+this.state.uid}><small id="edit-profile">View my profile</small></Link>
          <br/><br/>
          <p>{this.state.message}</p>
          <div id="orderList">
          <ol>
          {this.state.userLists.map(function(d, idx){
            return (
              <li key={idx}><Link to={"/user/"+d.UID}>{d.FirstName +" "+ d.LastName}</Link></li>
            )
          }.bind(this))}
          </ol>
          </div>
        </div>
    )
  }
};



export default Dashboard;
