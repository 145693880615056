import React, {Component} from "react";
import fire from '../fire';
import '../App.css';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';

var iit = require('../Assets/iit.jpeg');

class Login extends Component{
	constructor(props){
 		super(props);
  		this.state={
    		email:'',
    		password:'',
        message:'',
  		}	
	}
	componentDidMount(){
		
	}
	loginUser(){
		fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then(()=>{window.location.href="/"}).catch(function(error) {
  			// Handle Errors here.
  			var errorCode = error.code;
  			var errorMessage = error.message;
  			// console.log(errorMessage);
        this.setState({message:errorMessage});
  			// ...
		}.bind(this));
	};
  	render(){
      document.title="IIT Delhi'79 - Login"
    return(
    	<div id="home" style ={ { backgroundImage: "url("+iit+")" } }>
          <Header/>
          <div id="login">
      		<h3>Login</h3>
          <div class="fields">
          <p>Enter Email</p>
          <input type="email" onChange={(e)=>this.setState({email:e.target.value})}/>
          </div>
          <div class="fields">
          <p>Enter Password</p>
          <input type="password" onChange={(e)=>this.setState({password:e.target.value})}/>
          </div>
      		<button onClick={()=>this.loginUser()}>Submit</button>
          <br/><br/>
          <Link to="/sign-up">Don't have an account? Click here to sign-up</Link><br/><br/>
          <Link to="/forgot">Forgot password? Click here to reset</Link>
          <br/><br/>
          <p id="error">{this.state.message}</p>
          </div>
          <Footer/>
      </div>
    )
  }
};



export default Login;
