import React, {Component} from "react";
import { Link } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Dashboard from './Dashboard';
import ProfileList from './ProfileList';
import fire from '../fire';
import '../App.css';
import invite from '../Assets/invite.jpg';

var iit = require('../Assets/iit.jpeg');

class Home extends Component{
  constructor(props){
    super(props);
    this.state={
      email:'',
      isVerified:'',
      complete:'',
      message:'',
      message1:'Loading...',
    }
  }
  componentDidMount(){
    fire.auth().onAuthStateChanged(function(user) {
        if (user) {
          iit = require('../Assets/collage.jpg');
          // User is signed in.
          var email = user.email;
          var emailVerified = user.emailVerified;
          var uid = user.uid;
          fire.database().ref('/Profiles/' + user.uid).once('value').then(function(snapshot) {
            this.setState({complete:snapshot.val().Complete, message1:'Please complete your profile to see the profile of other batchmates.'});
          }.bind(this));
          this.setState({email:email, isVerified:emailVerified});
          // ...
        }
      }.bind(this));
    }
  resendVerification() {
    var user = fire.auth().currentUser;
    user.sendEmailVerification().then(function() {
      this.setState({message:'Email has been sent'})
    }.bind(this)).catch(function(error) {
      this.setState({message:error.message})
    }.bind(this));
  };
  render(){
    document.title="IIT Delhi'79 - Home"
    return(
    		<div id="home" style ={ { backgroundImage: "url("+iit+")" } }>
            <Header/>
            <div>
	      		{this.state.isVerified == false && this.state.complete == '' &&
              <div id="profile">
              <img src={invite} id="invite"/>
              </div>
            }
            {this.state.isVerified && this.state.complete == '1' &&
            <Dashboard/>
            } 
            {this.state.isVerified == false && this.state.email != '' &&
              <div id="profile">
                <b><p>Please verify email to proceed.</p></b><br/><br/>
                <button onClick={()=>this.resendVerification()}>Resend verification link</button>
                <p>{this.state.message}</p>
              </div>
            }
            {this.state.complete != '1' && this.state.isVerified &&
              <div id="profile">
                <b><p>{this.state.message1}</p></b><br/>
                <Link to="/profile"><small id="edit-profile">&#128394; Click here to complete your profile</small></Link>
                <br/>
                <hr/>
            <br/>
            <p class="info">
            Welcome Batch of 1979. As you know we are having our 40th Reunion in January of 2020, to help in preparation of that we would like you to fill in as many details as possible below. It will benefit us all by –
            </p>
            <ol class="info">
            <li class="info">Getting to know a bit more about each other. It has been after all 40 years!</li>
            <li class="info">When we meet it will help in getting conversations going. Perhaps spouses and children will make new friends and find common threads.</li>
            <li class="info">The Committee plans to publish an year book about all of us so please write and upload pictures.</li>
            </ol>
            <p class="info">
            You can write as much or as little as you desire. After you have filled in the mandatory fields you will be able to see what others have written and their pictures too.
            </p><br/>
            <p class="red">Fields marked in red are mandatory.</p>
            <br/>
            <hr/>
            <ProfileList/>
              </div>
            }
            </div>
            <Footer/>
	      	</div>
    )
  }
};



export default Home;
