import React, {Component} from "react";
import { Link } from 'react-router-dom';
import fire from '../fire';
import '../App.css';

class ProfileList extends Component{
  constructor(props){
    super(props);
    this.state={
      uid:'',
      userLists:[],
      url:'',
      message:'Loading...',
    }
  }
  componentDidMount(){
    fire.auth().onAuthStateChanged(function(user) {
      if (user) {
        var userProfiles = [];
        var query = fire.database().ref('Profiles').orderByChild('FirstName');
        query.on('child_added', function(snap) {
          var userDetails = snap.val();
          userProfiles.push(userDetails);
          this.setState({uid:user.uid, userLists:userProfiles, message:''})
        }.bind(this));
    }
  }.bind(this))
  }
  render(){
    // console.log(this.state.userLists);
    return(
    		<div id="profile">
          <h3>List of other batchmates who have already created their profile</h3>
          <br/>
          <p>{this.state.message}</p>
          <div id="orderList">
          <ol>
          {this.state.userLists.map(function(d, idx){
            return (
              <li key={idx}>{d.FirstName +" "+ d.LastName}</li>
            )
          }.bind(this))}
          </ol>
          </div>
        </div>
    )
  }
};



export default ProfileList;
