import React, {Component} from "react";
import fire from '../fire';
import { Link } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import no_image from '../Assets/no_image.png';

var iit = require('../Assets/iit.jpeg');
 
class User extends Component{
	constructor(props){
 		super(props);
  		this.state={
        FirstName:'Loading...',
        LastName:'',
        Year:'',
        LinkedIn:'',
        Discipline:'',
        Entrance:'',
        Hostel:'',
        PostGraduation:'',
        Phone:'',
        City:'',
        Spouse:'',
        Children:'',
        Pastime:'',
        Story:'',
        PastimeNow:'',
        Food:'',
        Movie:'',
        Actor:'',
        Career:'',
        Personal:'',
        Life:'',
        Plan:'',
        Impact:'',
        pictureURL1:no_image,
        pictureURL2:no_image,
        pictureURL3:no_image,
        pictureURL4:no_image,
        pictureURL5:no_image,
        pictureURL6:no_image,
        Email:'',
        Orientation1:'',
        Orientation2:'',
        Orientation3:'',
        Orientation4:'',
        Orientation5:'',
        Orientation6:'',
        Caption1:'',
        Caption2:'',
        Caption3:'',
        Caption4:'',
        Caption5:'',
        Caption6:'',
        ios:'',
  		}	
	}
	componentDidMount(){
    fire.auth().onAuthStateChanged(function(user) {
        if (user) {
          iit = require('../Assets/collage.jpg');  // ...
        }
    })
    fire.database().ref('/Profiles/' + this.props.match.params.id).once('value').then(function(snapshot) {
      var userDetails = snapshot.val();
      // console.log(userDetails);
      this.setState({FirstName: userDetails.FirstName, LastName: userDetails.LastName, Year: userDetails.Year, LinkedIn: userDetails.LinkedIn, Discipline: userDetails.Discipline, Entrance: userDetails.Entrance, Hostel: userDetails.Hostel, PostGraduation: userDetails.PostGraduation, Phone: userDetails.Phone, City: userDetails.City, Spouse: userDetails.Spouse, Children: userDetails.Children, Pastime: userDetails.Pastime, Story: userDetails.Story, PastimeNow: userDetails.PastimeNow, Food: userDetails.Food, Movie: userDetails.Movie, Actor: userDetails.Actor, Career: userDetails.Career, Personal: userDetails.Personal, Life: userDetails.Life, Plan: userDetails.Plan, Impact: userDetails.Impact, Email: userDetails.Email, Caption1: userDetails.Caption1, Caption2: userDetails.Caption2, Caption3: userDetails.Caption3, Caption4: userDetails.Caption4, Caption5: userDetails.Caption5, Caption6: userDetails.Caption6})
    }.bind(this))

    if(!!navigator.platform.match(/iPhone|iPod|iPad/)) {
      this.setState({ios:'Due to iOS limitations, some pictures may appear disoriented.'})
    } else {
      fire.database().ref('/Profiles/' + this.props.match.params.id).once('value').then(function(snapshot) {
      var userDetails = snapshot.val();
      this.setState({Orientation1: userDetails.Orientation1, Orientation2: userDetails.Orientation2, Orientation3: userDetails.Orientation3, Orientation4: userDetails.Orientation4, Orientation5: userDetails.Orientation5, Orientation6: userDetails.Orientation6})
      }.bind(this))      
    
    }


    fire.storage().ref('images/'+this.props.match.params.id+'_1.jpg').getDownloadURL().then((url)=>{
      this.setState({pictureURL1:url});
      document.getElementsByClassName("gallery").scrollTop = document.body.clientHeight * 0.15;
    }).catch()

    fire.storage().ref('images/'+this.props.match.params.id+'_2.jpg').getDownloadURL().then((url)=>{
      this.setState({pictureURL2:url});
      document.getElementsByClassName("gallery").scrollTop = document.body.clientHeight * 0.15;
    }).catch()

    fire.storage().ref('images/'+this.props.match.params.id+'_3.jpg').getDownloadURL().then((url)=>{
      this.setState({pictureURL3:url});
      document.getElementsByClassName("gallery").scrollTop = document.body.clientHeight * 0.15;
    }).catch()

    fire.storage().ref('images/'+this.props.match.params.id+'_4.jpg').getDownloadURL().then((url)=>{
      this.setState({pictureURL4:url});
      document.getElementsByClassName("gallery").scrollTop = document.body.clientHeight * 0.15;
    }).catch()

    fire.storage().ref('images/'+this.props.match.params.id+'_5.jpg').getDownloadURL().then((url)=>{
      this.setState({pictureURL5:url});
      document.getElementsByClassName("gallery").scrollTop = document.body.clientHeight * 0.15;
    }).catch()

    fire.storage().ref('images/'+this.props.match.params.id+'_6.jpg').getDownloadURL().then((url)=>{
      this.setState({pictureURL6:url});
      document.getElementsByClassName("gallery").scrollTop = document.body.clientHeight * 0.15;
    }).catch()
  }
  	render(){
      document.title = "IIT Delhi'79 - " + this.state.FirstName +" " + this.state.LastName
    return(
    	<div id="home" style ={ { backgroundImage: "url("+iit+")" } }>
          <Header/>
          <div id="profile">
          <Link to="/">Go back to dashboard</Link><br/><br/>
          <div id="profile-top">
      		<h1>{this.state.FirstName + " " + this.state.LastName}</h1>	
          <div class="overflow">
          <img src={this.state.pictureURL2} style={{WebkitTransform:this.state.Orientation1, transform:this.state.Orientation1}}/>
          </div>
          </div>
          <br/>
          <div class="fields">
          <p><b>Email:</b></p>
          <h4>{this.state.Email}</h4>
          </div>
          <div class="fields">
          <p><b>Year of Joining:</b></p>
          <h4>{this.state.Year}</h4>
          </div>
          <div class="fields">
          <p><b>Discipline:</b></p>
          <h4>{this.state.Discipline}</h4>
          </div>
          <div class="fields">
          <p><b>Entry Number:</b></p>
          <h4>{this.state.Entrance}</h4>
          </div>
          <div class="fields">
          <p><b>Hostel Name:</b></p>
          <h4>{this.state.Hostel}</h4>
          </div>
          <div class="fields">
          <p><b>Post Graduate Studies:</b></p>
          <h4>{this.state.PostGraduation}</h4>
          </div>
          <div class="fields">
          <p><b>Phone:</b></p>
          <h4>{this.state.Phone}</h4>
          </div>
          <div class="fields">
          <p><b>City:</b></p>
          <h4>{this.state.City}</h4>
          </div>
          <div class="fields">
          <p><b>Spouse / Significant Other:</b></p>
          <h4>{this.state.Spouse}</h4>
          </div>
          <div class="fields">
          <p><b>Children:</b></p>
          <h4>{this.state.Children}</h4>
          </div>
          <div class="fields">
          <p><b>Favorite Pastime in IIT:</b></p>
          <h4>{this.state.Pastime}</h4>
          </div>
          <div class="fields">
          <p><b>Significant / Juicy IIT story(ies):</b></p>
          <h4>{this.state.Story}</h4>
          </div>
          <div class="fields">
          <p><b>Favorite Pastime Now:</b></p>
          <h4>{this.state.PastimeNow}</h4>
          </div>
          <div class="fields">
          <p><b>Favorite Food(s):</b></p>
          <h4>{this.state.Food}</h4>
          </div>
          <div class="fields">
          <p><b>Favorite Movie(s):</b></p>
          <h4>{this.state.Movie}</h4>
          </div>
          <div class="fields">
          <p><b>Favorite Actor/Actress/Singer:</b></p>
          <h4>{this.state.Actor}</h4>
          </div>
          <div class="fields">
          <p><b>Career Journey:</b></p>
          <h4>{this.state.Career}</h4>
          </div>
          <div class="fields">
          <p><b>LinkedIn Profile:</b></p>
          <a href={this.state.LinkedIn} target="_blank">{this.state.LinkedIn}</a>
          </div>
          <div class="fields">
          <p><b>Personal Journey:</b></p>
          <h4>{this.state.Personal}</h4>
          </div>
          <div class="fields">
          <p><b>Event/person that changed my life:</b></p>
          <h4>{this.state.Life}</h4>
          </div>
          <div class="fields">
          <p><b>Plans for the rest of my precious days:</b></p>
          <h4>{this.state.Plan}</h4>
          </div>
          <div class="fields">
          <p><b>How would I devote 500 hours or spend $100,000 to make the most social impact?:</b></p>
          <h4>{this.state.Impact}</h4>
          </div>
          <h3 id="pictureHeading">Pictures</h3>
          <p id="error">{this.state.ios}</p>
          <div class="gallery-wrapper">
          <div class="caption-wrapper">
          <div class="gallery">
          <video></video>
          <img src={this.state.pictureURL2} width="50%" style={{WebkitTransform:this.state.Orientation1, transform:this.state.Orientation1}}/>
          </div>
          <b><p>Current Picture</p></b>
          <br/>
          <p>{this.state.Caption1}</p>
          <br/>
          </div>
          <div class="caption-wrapper">
          <div class="gallery">
          <video></video>
          <img src={this.state.pictureURL4} width="50%" style={{WebkitTransform:this.state.Orientation2, transform:this.state.Orientation2}}/>
          </div>
          <b><p>Family Picture</p></b>
          <br/>
          <p>{this.state.Caption2}</p>
          <br/>
          </div>
          </div>

          <div class="gallery-wrapper">
          <div class="caption-wrapper">
          <div class="gallery">
          <video></video>
          <img src={this.state.pictureURL3} width="50%" style={{WebkitTransform:this.state.Orientation3, transform:this.state.Orientation3}}/>
          </div>
          <b><p>IIT Days Picture (1)</p></b>
          <br/>
          <p>{this.state.Caption3}</p>
          <br/>
          </div>
          <div class="caption-wrapper">
          <div class="gallery">
          <video></video>
          <img src={this.state.pictureURL1} width="50%" style={{WebkitTransform:this.state.Orientation4, transform:this.state.Orientation4}}/>
          </div>
          <b><p>IIT Days Picture (2)</p></b>
          <br/>
          <p>{this.state.Caption4}</p>
          <br/>
          </div>
          </div>

          <div class="gallery-wrapper">
          <div class="caption-wrapper">
          <div class="gallery">
          <video></video>
          <img src={this.state.pictureURL5} style={{WebkitTransform:this.state.Orientation5, transform:this.state.Orientation5}}/>
          </div>
          <b><p>Additional Picture (1)</p></b>
          <br/>
          <p>{this.state.Caption5}</p>
          <br/>
          </div>
          <div class="caption-wrapper">
          <div class="gallery">
          <video></video>
          <img src={this.state.pictureURL6} style={{WebkitTransform:this.state.Orientation6, transform:this.state.Orientation6}}/>
          </div>
          <b><p>Additional Picture (2)</p></b>
          <br/>
          <p>{this.state.Caption6}</p>
          <br/>
          </div>
          </div>
          </div>
          <Footer/>
      </div>
    )
  }
};



export default User;
