import React, {Component} from "react";
import { Link } from 'react-router-dom';
import fire from '../fire';
import '../App.css';
import alumni from '../Assets/alumni.png';

class Header extends Component{
  constructor(props){
  	super(props);
  	this.state={
    	email:'Guest',
    	isVerified:'',
  	}	
  }
  componentDidMount(){
  		fire.auth().onAuthStateChanged(function(user) {
		  if (user) {
		    // User is signed in.
		    var email = user.email;
		    var emailVerified = user.emailVerified;
		    var uid = user.uid;
		    this.setState({email:email, isVerified:emailVerified});
		    // ...
		  }
		}.bind(this));
  }
  signOutUser() {
  	fire.auth().signOut().then(function() {
	  console.log("Signed Out");
	  window.location.href="/";
	}).catch(function(error) {
	  // An error happened.
	});
  }
  render(){
    return(
    		<div id="header">
    			<div id="header-logo">
    			<Link to="/"><img src={alumni} width="100%"/></Link>
    			</div>
    			<h2>IIT Delhi'79 Alumni</h2>
		      	{this.state.email == 'Guest' &&
		      	<div>
		      	<Link to="/login"><button class="header-button">Login</button></Link>
		      	</div>
		      	}
		      	{this.state.email !== "Guest" &&
		      	<button onClick={()=>this.signOutUser()} class="header-button">Sign Out</button>
		      	}
	      	</div>
    )
  }
};


export default Header;
